import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource, Request} from '@shared/services/http-resource';

import {Job, JobStep, Recipe} from '../../types';
import {JobsRequestParams, JobsResponse} from '../../services/recipe.service';

import {JobDetailsResponse, JobNetworkLog, JobReport, JobStepDetails} from './job-details.types';

@Injectable({
  providedIn: 'root',
})
export class JobDetailsService {
  private webApiResource: HttpResource;

  constructor(private http: HttpClient) {
    this.webApiResource = new HttpResource(this.http, {
      url: '/web_api/recipes/{{ recipeId }}/jobs/{{ jobId }}/{{ action }}',
    });
  }

  async getJobDetails(recipeId: Recipe['id'], jobId: Job['id']): Promise<JobDetailsResponse> {
    const [jobResponse, stepDetailsResponse, reportResponse] = await Promise.all([
      this.webApiResource.get<JobDetailsResponse>({recipeId, jobId}),
      this.webApiResource.get<JobStepDetails>(
        {recipeId, jobId, action: 'line_details'},
        {query: {stringify_big_numbers: true}},
      ),
      this.webApiResource.get<JobReport>({recipeId, jobId, action: 'report'}),
    ]);

    jobResponse.job.data = stepDetailsResponse.line_details;
    jobResponse.job.lines_truncated = stepDetailsResponse.lines_truncated;
    jobResponse.job.kms_error = stepDetailsResponse.kms_error;
    jobResponse.job.report = reportResponse.report;

    return jobResponse;
  }

  async getNetworkLog(recipeId: Recipe['id'], jobId: Job['id'], stepNumber: JobStep['number']): Promise<JobNetworkLog> {
    return this.webApiResource.get(
      {
        recipeId,
        jobId,
        action: 'network_log',
      },
      {query: {step: stepNumber}},
    );
  }

  getRepeats(
    recipeId: Recipe['id'],
    masterJobId: Job['master_job_id'],
    query: Pick<JobsRequestParams, 'offset_job_id' | 'offset_count' | 'prev' | 'per_page'> & {
      repeat: JobsRequestParams['avoid_cache'];
    },
  ): Request<JobsResponse> {
    return this.webApiResource.get({recipeId, jobId: masterJobId, action: 'repeats'}, {query});
  }
}
