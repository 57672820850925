import {AfterViewInit, Directive, Input} from '@angular/core';

import {KeyboardNavigationConfig} from './keyboard-navigation.types';
import {KeyboardNavigationService} from './keyboard-navigation.service';

@Directive({
  selector: '[wKeyboardNavigation]',
  exportAs: 'keyboardNavigation',
  standalone: true,
  providers: [KeyboardNavigationService],
})
export class KeyboardNavigationDirective implements AfterViewInit {
  @Input('wKeyboardNavigation') config?: KeyboardNavigationConfig | '';

  constructor(private keyboardNavigationService: KeyboardNavigationService) {}

  ngAfterViewInit() {
    if (this.config) {
      this.keyboardNavigationService.configure(this.config);
    }
  }

  autofocus() {
    this.keyboardNavigationService.autofocus();
  }
}
