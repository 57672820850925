import {AuthUser} from '../../services/auth-user';
import {Recipe} from '../../types';

import {HvrEligibility} from './usage-dashboard-api.service';
import {
  CHART_BILLABLE_TASKS_GROUP,
  CHART_HVR_ELIGIBLE_TASKS_GROUP,
  CHART_SPECIAL_GROUP_NAME_MAPPER,
  CHART_SPECIAL_GROUP_TYPE_MAPPER,
  DEFAULT_SCOPE_GROUP_BY,
  NEAR_LIMIT_MULTIPLIER,
  SCOPE_ALLOWED_GROUP_BY,
} from './usage-dashboard.constants';
import {
  ChartResourceGroup,
  HvrRecipe,
  HvrTier,
  UsageChartInterval,
  UsageChartIntervalSpecialGroupKey,
  UsageGroupBy,
  UsageScopeFilter,
} from './usage-dashboard.types';

export function isAllowedGroupBy(scope: UsageScopeFilter, groupBy: UsageGroupBy): boolean {
  return SCOPE_ALLOWED_GROUP_BY[scope].includes(groupBy);
}

export function getDefaultGroupBy(scope: UsageScopeFilter): UsageGroupBy {
  return DEFAULT_SCOPE_GROUP_BY[scope];
}

export function getPercents(value: number, total: number): number {
  return Math.round((value / total) * 100);
}

export function isNearLimit(limit: number, value: number): boolean {
  if (limit === 0) {
    return false;
  }

  return value >= limit * NEAR_LIMIT_MULTIPLIER;
}

export function isTBPV2MembershipPlan(user: AuthUser): boolean {
  return user.membership.type === 'tbp_v2';
}

export function hasApiProxyAccess(user: AuthUser): boolean {
  return user.hasAdHocFeatures('realtime_usage');
}

export function isSpecialGroupKey(groupKey: string): groupKey is UsageChartIntervalSpecialGroupKey {
  return groupKey === CHART_HVR_ELIGIBLE_TASKS_GROUP || groupKey === CHART_BILLABLE_TASKS_GROUP;
}

export function getSpecialGroupType(groupKey: UsageChartIntervalSpecialGroupKey): ChartResourceGroup {
  return CHART_SPECIAL_GROUP_TYPE_MAPPER[groupKey];
}

export function getSpecialGroupName(groupKey: UsageChartIntervalSpecialGroupKey): string {
  return CHART_SPECIAL_GROUP_NAME_MAPPER[groupKey];
}

export function isGroupedByHvrEligibleTasks(intervals: UsageChartInterval[]): boolean {
  return intervals.some(interval => interval.groups && interval.groups[CHART_HVR_ELIGIBLE_TASKS_GROUP] > 0);
}

export function canRecipeBeUpgradedForHvrTier(
  recipeId: Recipe['id'],
  tier: HvrRecipe['tier'],
  hvrEligibility: HvrEligibility,
): boolean {
  return hvrEligibility[`eligible_for_hvr${tier}`].includes(recipeId);
}

export function getEligibleForUpgradeHvrTier(recipeId: Recipe['id'], hvrEligibility: HvrEligibility): HvrTier | null {
  if (canRecipeBeUpgradedForHvrTier(recipeId, 1, hvrEligibility)) {
    return 1;
  }

  if (canRecipeBeUpgradedForHvrTier(recipeId, 2, hvrEligibility)) {
    return 2;
  }

  if (canRecipeBeUpgradedForHvrTier(recipeId, 3, hvrEligibility)) {
    return 3;
  }

  return null;
}

export function canRecipeBeUpgradedForAnyHvrTier(recipeId: Recipe['id'], hvrEligibility: HvrEligibility): boolean {
  return (
    canRecipeBeUpgradedForHvrTier(recipeId, 1, hvrEligibility) ||
    canRecipeBeUpgradedForHvrTier(recipeId, 2, hvrEligibility) ||
    canRecipeBeUpgradedForHvrTier(recipeId, 3, hvrEligibility)
  );
}

export function getEligibleForUpgradeHVR(hvrRecipes: HvrRecipe[], hvrEligibility: HvrEligibility): HvrRecipe[] {
  return hvrRecipes.filter(recipe => canRecipeBeUpgradedForAnyHvrTier(recipe.recipe_id, hvrEligibility));
}

export function getEligibleHvrTotalCount(hvrEligibility: HvrEligibility): number {
  return (
    hvrEligibility.eligible_for_hvr1.length +
    hvrEligibility.eligible_for_hvr2.length +
    hvrEligibility.eligible_for_hvr3.length
  );
}
