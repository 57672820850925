import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource, Request} from '@shared/services/http-resource';

import {FormulaSuggestion, RubyType} from '../types';

import {AbstractSuggestionsRegistry} from './abstract-suggestions-registry.service';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsRegistry extends AbstractSuggestionsRegistry<RubyType> {
  private resource: HttpResource;

  constructor(private http: HttpClient) {
    super();

    this.resource = new HttpResource(this.http, {
      url: '/web_api/formula_suggestions.json',
    });
  }

  override fetchSuggestions(): Request<Array<FormulaSuggestion<RubyType>>> {
    return this.resource.getAll();
  }
}
