import {Injectable} from '@angular/core';

import {FieldWithProperties, FieldsInput, SchemaField} from '../../types';
import {FieldHelper} from '../form-fields/field-helper.service';
import {SchemaHelper} from '../../services/schema-helper';

export interface FieldsVisibilityMap {
  [path: string]: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OptionalFieldsHelper {
  constructor(
    private fieldHelper: FieldHelper,
    private schemaHelper: SchemaHelper,
  ) {}

  createVisibilityMap(
    schema: SchemaField[],
    visibleFieldList: string[] | undefined = [],
    hiddenFieldList: string[] | undefined = [],
    stepInput: FieldsInput,
  ): FieldsVisibilityMap {
    /** Handling hidden by user sticky fields and added by user optional fields */
    const visibilityMap = [...visibleFieldList, ...hiddenFieldList].reduce<FieldsVisibilityMap>(
      (fieldMap, dotJoinedPath) => {
        fieldMap[dotJoinedPath] = visibleFieldList.includes(dotJoinedPath) && !hiddenFieldList.includes(dotJoinedPath);

        return fieldMap;
      },
      {},
    );

    /** Forcibly showing fields with a non-empty value and sticky fields, which are not specially hidden by the user */
    this.schemaHelper.forEachField(schema, field => {
      if (this.fieldHelper.isMapped(field, stepInput) || (field.sticky && !this.isHidden(field, visibilityMap))) {
        const pathString = this.fieldHelper.joinPathByDot(this.fieldHelper.getQualifiedPath(field));

        visibilityMap[pathString] = true;
      }
    });

    return visibilityMap;
  }

  filterVisible(schema: SchemaField[], visibilityMap: FieldsVisibilityMap, stepInput: FieldsInput): SchemaField[] {
    return this.filterSchemaRecursively(
      schema,
      field =>
        !this.isHidable(field) || this.fieldHelper.isMapped(field, stepInput) || this.isVisible(field, visibilityMap),
    );
  }

  filterOptional(schema: SchemaField[]): SchemaField[] {
    return this.filterSchemaRecursively(
      schema,
      field =>
        this.isHidable(field) || (this.fieldHelper.hasChildren(field) && !this.fieldHelper.isKeyValueField(field)),
    );
  }

  isVisible(field: SchemaField, visibilityMap: FieldsVisibilityMap): boolean {
    const pathString = this.fieldHelper.joinPathByDot(this.fieldHelper.getQualifiedPath(field));

    return visibilityMap[pathString] === true;
  }

  isHidden(field: SchemaField, visibilityMap: FieldsVisibilityMap): boolean {
    const pathString = this.fieldHelper.joinPathByDot(this.fieldHelper.getQualifiedPath(field));

    return visibilityMap[pathString] === false;
  }

  private isHidable(field: SchemaField): boolean {
    return Boolean(field.optional && !field.ngIf && !field.optionalExpression);
  }

  private filterSchemaRecursively(schema: SchemaField[], filterFn: (field: SchemaField) => boolean): SchemaField[] {
    return schema.reduce<SchemaField[]>((fields, field) => {
      let picked = filterFn(field);

      if (this.fieldHelper.hasChildren(field) && !this.fieldHelper.isKeyValueField(field)) {
        const properties = this.filterSchemaRecursively(field.properties, filterFn);

        // Clone complex field to prevent changes to the original schema
        field = {
          ...field,
          properties,
        } as FieldWithProperties;
        picked = Boolean(properties.length);
      }

      if (picked) {
        fields.push(field);
      }

      return fields;
    }, []);
  }
}
