import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {Observable} from 'rxjs';
import {CommonModule} from '@angular/common';

import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';
import {LayoutService} from '@shared/services/layout.service';
import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';
import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';

import {AppHeaderUserComponent} from '../app-header-user/app-header-user.component';
import {AppHeaderSwitcherComponent} from '../app-header-switcher/app-header-switcher.component';
import {AppMenuItemIconDirective} from '../app-menu-item/app-menu-item-icon.directive';
import {UserAvatarModule} from '../../modules/user-avatar/user-avatar.module';
import {AppSidebarService} from '../app-sidebar/app-sidebar.service';
import {MixpanelDirective} from '../../directives/mixpanel.directive';
import {AppMobileSidebarService} from '../app-mobile-sidebar/app-mobile-sidebar.service';
import {AbstractAppContextSwitcher} from '../app-context-switcher/abstract-app-context-switcher';
import {AppContextSwitcherIconComponent} from '../app-context-switcher-icon/app-context-switcher-icon.component';
import {AppLayoutTheme} from '../../types';

import {AppHeaderLogoDirective} from './app-header-logo.directive';

@Component({
  selector: 'w-app-header',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AppHeaderUserComponent,
    AppHeaderSwitcherComponent,
    AppMenuItemIconDirective,
    UserAvatarModule,
    SvgIconComponent,
    IconButtonComponent,
    MixpanelDirective,
    KeyboardNavigationItemDirective,
    AppContextSwitcherIconComponent,
  ],
})
export class AppHeaderComponent extends AbstractAppContextSwitcher implements OnInit {
  @Input({required: true}) theme: AppLayoutTheme;

  @ContentChild(AppHeaderLogoDirective, {read: TemplateRef}) customLogoTemplate?: TemplateRef<void>;

  readonly isMobileLayout$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private appSidebarService: AppSidebarService,
    private appMobileSidebarService: AppMobileSidebarService,
  ) {
    super();
    this.isMobileLayout$ = this.layoutService.isMobileLayout$;
  }

  openSidebar() {
    this.appSidebarService.openSidebar();
  }

  openMobileSidebar() {
    this.appMobileSidebarService.openSidebar();
  }
}
