import {Injectable} from '@angular/core';
import {merge} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {ResizeObservedElement} from '@shared/directives/element-resize.directive';
import {subscriptions} from '@shared/services/subscriptions';

@Injectable({
  providedIn: 'root',
})
export class StickyAreaService {
  // Array of elements that may *possibly* be fixed or sticky
  private elements = new Set<ResizeObservedElement>();
  private height = 0;
  private subs = subscriptions();

  get currentHeight(): number {
    return this.height;
  }

  add(elem: ResizeObservedElement) {
    this.elements.add(elem);
    this.updateHeight();
    this.subscribeToHeightChanges();
  }

  remove(elem: ResizeObservedElement) {
    this.elements.delete(elem);
    this.updateHeight();
    this.subscribeToHeightChanges();
  }

  private subscribeToHeightChanges() {
    const events = [...this.elements].map(item => item.onChange);

    this.subs.unsubscribe();
    this.subs.add(
      merge(...events)
        .pipe(debounceTime(0))
        .subscribe(this.updateHeight),
    );
  }

  private getTotalStickyAreaHeight(): number {
    let totalHeight = 0;

    for (const item of this.elements) {
      const style = window.getComputedStyle(item.element);

      /*
       * Seems like sometimes `getComputedStyle` may return `null`
       * Fixes https://sentry.awsprod.workato.com/workato/workato-frontend/issues/94843/?referrer=slack
       */
      if (style?.position === 'fixed' || style?.position === 'sticky') {
        totalHeight = Math.max(totalHeight, item.element.offsetHeight + parseInt(style.top, 10));
      }
    }

    return totalHeight;
  }

  private updateHeight = () => {
    this.height = this.getTotalStickyAreaHeight();
    document.documentElement.style.setProperty('--app-layout-sticky-area-height', `${this.height}px`);
  };
}
