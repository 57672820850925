import _ from 'lodash';
import {inject} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {Request} from '@shared/services/http-resource';
import {AlertService} from '@shared/services/alert';
import {LRUCache} from '@shared/services/lru-cache';
import {nonNullable} from '@shared/utils/non-nullable';

import {FormulaSuggestion, RubyType, SuggestionType} from '../types';
import {
  AutocompleteSuggestions,
  FormulaSuggestions,
  GroupedSuggestions,
  SearchSuggestions,
} from '../modules/form-fields/field-suggestions/field-suggestions.types';

const DESCRIPTION_MATCH_PRIORITY = 10;

function removeLeadingDot(str: string): string {
  return str.startsWith('.') ? str.slice(1) : str;
}

function createCacheKey(...args: Array<string | null>): string {
  return args.filter(Boolean).join('_');
}

interface SuggestFunctionsFilterOptions<TType extends string> {
  inputType: TType | null;
  returnTypes?: TType[];
  globalFunctionsOnly?: boolean;
  allowFullMatches?: boolean;
  hideOperators?: string[];
}

export abstract class AbstractSuggestionsRegistry<TType extends string = RubyType> {
  abstract fetchSuggestions(): Request<Array<FormulaSuggestion<TType>>>;

  initialized$: Observable<boolean>;

  private functions: Array<FormulaSuggestion<TType>> = [];
  private operators: Array<FormulaSuggestion<TType>> = [];
  private methods: Array<FormulaSuggestion<TType>> = [];

  private functionsMap = new Map<FormulaSuggestion<TType>['name'], FormulaSuggestion<TType>>();

  private methodsMap = new Map<FormulaSuggestion<TType>['name'], FormulaSuggestion<TType>>();

  private cache = {
    suggest: new LRUCache<string, FormulaSuggestions<TType>>(20),
    search: new LRUCache<string, SearchSuggestions<TType>>(50),
    autocomplete: new LRUCache<string, AutocompleteSuggestions<TType>>(50),
  };

  private _initialized: BehaviorSubject<boolean>;
  private alertService = inject(AlertService);

  constructor() {
    this._initialized = new BehaviorSubject(false);
    this.initialized$ = this._initialized.asObservable();
  }

  async init(presetSuggestions?: Array<FormulaSuggestion<TType>>) {
    if (this.initialized) {
      return;
    }

    if (presetSuggestions) {
      this.setSuggestions(presetSuggestions);

      return;
    }

    let suggestions: Array<FormulaSuggestion<TType>> = [];

    try {
      suggestions = await this.fetchSuggestions();
    } catch {
      this.alertService.error('Suggestions for the recipe fields are not available at the moment.');

      return;
    }

    this.setSuggestions(suggestions);
  }

  getMethod(methodName: string): FormulaSuggestion<TType> | undefined {
    return this.methodsMap.get(methodName);
  }

  getFunction(functionName: string): FormulaSuggestion<TType> | undefined {
    return this.functionsMap.get(functionName);
  }

  getMethodOrFunction(name: string): FormulaSuggestion<TType> | undefined {
    return this.getMethod(name) ?? this.getFunction(name);
  }

  getOperator(name: string): FormulaSuggestion<TType> | undefined {
    return this.operators.find(operator => operator.name === name);
  }

  getOperatorByApiName(name: string): FormulaSuggestion<TType> | undefined {
    return this.operators.find(operator => operator.api_name === name);
  }

  getFunctionByApiName(name: string): FormulaSuggestion<TType> | undefined {
    return this.functions.find(operator => operator.api_name === name);
  }

  suggest(types: SuggestionType[], opts: SuggestFunctionsFilterOptions<TType>): FormulaSuggestions<TType> {
    const key = createCacheKey(...types, opts.inputType, ...(opts.returnTypes ?? []));
    let result = this.cache.suggest.get(key);

    if (result) {
      return result;
    }

    const fnSuggestions = this.getFunctionSuggestions(types).filter(
      fs => this.inputTypeMatch(fs, opts.inputType) && this.returnTypesMatch(fs, opts.returnTypes),
    );
    const fnSuggestionsByCategory = this.groupSuggestionsByCategory(fnSuggestions);
    const showRecommendations = !opts.globalFunctionsOnly && Boolean(opts.inputType || types.includes('function'));
    const showTypeCategories = Boolean(opts.globalFunctionsOnly || (!opts.inputType && !types.includes('function')));
    const showOperators = Boolean(types.includes('operator'));

    result = {
      inputType: opts.inputType,
      types,
      sections: {
        recommendations: showRecommendations ? this.getRecommendations(fnSuggestions, opts.inputType) : [],
        operator: showOperators ? this.getOperators(opts.inputType, opts.hideOperators) : [],
        conversion: fnSuggestionsByCategory.conversion ?? [],
        string: showTypeCategories ? fnSuggestionsByCategory.string ?? [] : [],
        number: showTypeCategories ? fnSuggestionsByCategory.number ?? [] : [],
        array: showTypeCategories ? fnSuggestionsByCategory.array ?? [] : [],
        date: showTypeCategories ? fnSuggestionsByCategory.date ?? [] : [],
        conditional: fnSuggestionsByCategory.conditional ?? [],
        other: [],
      },
    };

    result.sections.other = this.getRemainingSuggestions(fnSuggestions, result.sections);

    this.cache.suggest.put(key, result);

    return result;
  }

  search(query: string, types: SuggestionType[], opts: SuggestFunctionsFilterOptions<TType>): SearchSuggestions<TType> {
    query = removeLeadingDot(query);

    const key = createCacheKey(query, ...types, opts.inputType, ...(opts.returnTypes ?? []));
    let result = this.cache.search.get(key);

    if (result) {
      return result;
    }

    result = _(this.getFunctionSuggestions(types))
      .map(fs => ({
        fs,
        typeMatch: this.inputTypeMatch(fs, opts.inputType) && this.returnTypesMatch(fs, opts.returnTypes),
        directMatch: this.directMatch(query, fs, true, opts.allowFullMatches),
        indirectMatch: this.indirectMatch(query, fs),
      }))
      .sortBy(entry => entry.directMatch ?? Infinity)
      .reduce<SearchSuggestions<TType>>(
        (memo, {fs, typeMatch, directMatch, indirectMatch}) => {
          if (directMatch === null && !indirectMatch) {
            return memo;
          }

          memo.hasResults = true;

          if (!typeMatch) {
            memo.otherMatches.push(fs);
          } else if (directMatch !== null) {
            memo.directMatches.push(fs);
          } else {
            memo.indirectMatches.push(fs);
          }

          return memo;
        },
        {query, hasResults: false, directMatches: [], indirectMatches: [], otherMatches: []},
      );

    this.cache.search.put(key, result);

    return result;
  }

  autocomplete(query: string, types: SuggestionType[]): AutocompleteSuggestions<TType> {
    query = removeLeadingDot(query);

    const key = createCacheKey(query, ...types);
    let result = this.cache.autocomplete.get(key);

    if (result) {
      return result;
    }

    const matches = _(this.getFunctionSuggestions(types))
      .map(fs => ({fs, directMatch: this.directMatch(query, fs)}))
      .filter(entry => entry.directMatch !== null)
      .sortBy(entry => entry.directMatch)
      .map(entry => entry.fs)
      .value();

    result = {query, matches};

    this.cache.autocomplete.put(key, result);

    return result;
  }

  protected setSuggestions(suggestions: Array<FormulaSuggestion<TType>>) {
    const functions: Array<FormulaSuggestion<TType>> = [];
    const operators: Array<FormulaSuggestion<TType>> = [];
    const methods: Array<FormulaSuggestion<TType>> = [];

    suggestions.forEach(fs => {
      if (fs.options.operator) {
        fs.type = 'operator';
        operators.push(fs);
      } else if (fs.options.global) {
        fs.type = 'function';
        functions.push(fs);
      } else {
        fs.type = 'method';
        methods.push(fs);
      }
    });

    this.functions = functions;
    this.operators = operators;
    this.methods = methods;
    this.functionsMap = new Map(functions.map(fs => [fs.name, fs]));
    this.methodsMap = new Map(methods.map(fs => [fs.name, fs]));
    this._initialized.next(true);
  }

  private get initialized(): boolean {
    return this._initialized.value;
  }

  private getFunctionSuggestions(types: SuggestionType[]): Array<FormulaSuggestion<TType>> {
    let result: Array<FormulaSuggestion<TType>> = [];

    if (types.includes('function')) {
      result = result.concat(this.functions);
    }

    if (types.includes('method')) {
      result = result.concat(this.methods);
    }

    return result;
  }

  private getRemainingSuggestions(
    suggestions: Array<FormulaSuggestion<TType>>,
    exclude: FormulaSuggestions<TType>['sections'],
  ): Array<FormulaSuggestion<TType>> {
    const excludedSuggestions = _(exclude).values().flatten().value();

    return _.difference(suggestions, excludedSuggestions);
  }

  private getRecommendations(
    suggestions: Array<FormulaSuggestion<TType>>,
    inputType: TType | null,
  ): Array<FormulaSuggestion<TType>> {
    return _(suggestions)
      .filter(fs => !inputType || (fs.options.types?.indexOf(inputType) ?? 0) >= 0)
      .orderBy(fs => fs.options.usage ?? 0, 'desc')
      .take(5)
      .value();
  }

  private getOperators(inputType: TType | null = null, hideOperators: string[] = []): Array<FormulaSuggestion<TType>> {
    return this.operators.filter(
      operator => !hideOperators.includes(operator.name) && this.inputTypeMatch(operator, inputType),
    );
  }

  private groupSuggestionsByCategory(suggestions: Array<FormulaSuggestion<TType>>): GroupedSuggestions<TType> {
    return _(suggestions)
      .flatMap(fs => (fs.options.categories ?? []).map(category => ({category, fs})))
      .groupBy(entry => entry.category)
      .mapValues(entries =>
        _(entries)
          .map(entry => entry.fs)
          .orderBy(fs => fs.options.usage ?? 0, 'desc')
          .value(),
      )
      .value();
  }

  private inputTypeMatch(fs: FormulaSuggestion<TType>, inputType: TType | null): boolean {
    if (!inputType || (!fs.options.types && !fs.options.signatures)) {
      return true;
    }

    const types = new Set<TType>([
      ...(fs.options.types ?? []),
      ...(fs.options.signatures?.flatMap(signature => signature.operands.flatMap(operand => operand.types)) ?? []),
    ]);

    return types.has(inputType);
  }

  private returnTypesMatch(fs: FormulaSuggestion<TType>, returnTypes?: TType[]): boolean {
    if (!returnTypes || !returnTypes.length) {
      return true;
    }

    const types = new Set<TType>(
      [fs.options.result_type, ...(fs.options.signatures?.map(signature => signature.return_type) ?? [])].filter(
        nonNullable,
      ),
    );

    return returnTypes.some(type => types.has(type));
  }

  private directMatch(
    query: string,
    fs: FormulaSuggestion<TType>,
    searchDescription = false,
    allowFullMatches = false,
  ): number | null {
    let matchedWordIndex = this.searchMatchedWordIndex(fs.name, query, '_', allowFullMatches);

    if (matchedWordIndex !== null) {
      return matchedWordIndex;
    }

    if (searchDescription) {
      matchedWordIndex = this.searchMatchedWordIndex(fs.options.description, query);

      if (matchedWordIndex !== null) {
        // Description matches should be shown after name matches
        return DESCRIPTION_MATCH_PRIORITY + matchedWordIndex;
      }
    }

    return null;
  }

  private indirectMatch(query: string, fs: FormulaSuggestion<TType>): boolean {
    return Boolean(fs.options.search_tags?.some(tag => tag.toLowerCase() === query.toLowerCase()));
  }

  private searchMatchedWordIndex(
    str: string,
    query: string,
    wordSeparator = ' ',
    allowFullMatches = false,
  ): number | null {
    str = str.toLowerCase();
    query = query.toLowerCase();

    let position = 0;
    let matchIndex = 0;

    // Full matches should be excluded from search
    if (str === query && !allowFullMatches) {
      return null;
    }

    do {
      if (str.startsWith(query, position)) {
        return matchIndex;
      }

      position = str.indexOf(wordSeparator, position) + 1;
      matchIndex++;
    } while (position > 0 && position < str.length);

    return null;
  }
}
