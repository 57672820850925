import _ from 'lodash';
import {NgModule} from '@angular/core';
import {QuicklinkStrategy} from 'ngx-quicklink';

import {RouterModule} from '@shared/modules/router/router.module';

import {assetsPageUrlMatcher} from './pages/assets-page/assets-page-url-matcher';
import {packagesUrlMatcher} from './pages/packages/packages-url-matcher';
import {teamMembersUrlMatcher} from './pages/team-members/team-members-url-matcher';
import {appVersionUpdateGuard} from './modules/app-version/app-version-update.guard';
import {AppLayoutComponent} from './components/app-layout/app-layout.component';
import {AppRoute} from './types';
import {browsePageGuard} from './pages/browse-page/browse.guard';
import {authUserInitializedGuard} from './guards/auth-user-initialized.guard';
import {teamRolesUrlMatcher} from './pages/team-roles/team-roles-url-matcher';
import {restrictedRouteGuard} from './guards/restricted-route.guard';

export const routes: AppRoute[] = [
  {
    path: '',
    /**
     * Using `canActivate` guard here to delay pages init until `AuthUser` is ready.
     * This doesn't block route matching - requesting lazy JS chunk for matched route will be done
     * in parallel with `AuthUser` initialization request, but rendering of the route component will be blocked
     * until this guard finishes.
     */
    canActivate: [authUserInitializedGuard],
    canActivateChild: [appVersionUpdateGuard],
    children: [
      {
        path: '',
        component: AppLayoutComponent,
        canActivate: [restrictedRouteGuard],
        canActivateChild: [restrictedRouteGuard],
        children: _.compact([
          {
            path: 'workato_schemas',
            loadChildren: () => import('./pages/shared-schema/shared-schema.module').then(m => m.SharedSchemaModule),
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
          },
          {
            path: 'deployments',
            loadChildren: () =>
              import('./pages/deployments-details/deployments-details-page.module').then(
                m => m.DeploymentsDetailsPageModule,
              ),
          },
          {
            path: 'project_builds',
            loadChildren: () =>
              import('./pages/project-build-details/project-build-details-page.module').then(
                m => m.ProjectBuildDetailsPageModule,
              ),
          },
          {
            path: 'workato_templates',
            loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule),
          },
          {
            path: 'on_prem_groups',
            loadChildren: () => import('./pages/secure-agent/secure-agent.module').then(m => m.SecureAgentModule),
          },
          {
            path: 'secure_agents',
            redirectTo: 'on_prem_groups',
          },
          {
            path: 'lookup_tables',
            loadChildren: () => import('./pages/lookup-table/lookup-table.module').then(m => m.LookupTableModule),
          },
          {
            path: 'logs',
            loadChildren: () => import('./pages/recipe-logs/recipe-logs.module').then(m => m.RecipeLogsModule),
          },
          {
            path: 'data_tables',
            loadChildren: () => import('./pages/data-table/data-table-page.module').then(m => m.DataTablePageModule),
          },
          {
            path: 'data_pipelines',
            loadChildren: () =>
              import('./pages/data-pipeline/data-pipeline-page.module').then(m => m.DataPipelinePageModule),
          },
          {
            path: 'workflow_portals',
            loadChildren: () =>
              import('./pages/people-tasks/people-tasks-admin.module').then(m => m.PeopleTasksAdminModule),
          },
          {
            matcher: assetsPageUrlMatcher,
            pathMatch: 'full',
            loadChildren: () => import('./pages/assets-page/assets-page.module').then(m => m.AssetsPageModule),
          },
          {
            path: 'recipes/diff',
            loadChildren: () =>
              import('./pages/recipe-diff-page/recipe-diff-entrypoints.module').then(
                m => m.RecipeDiffEntrypointsModule,
              ),
          },
          {
            path: 'recipes/:id/admin',
            loadChildren: () => import('./pages/recipe-admin/recipe-admin.module').then(m => m.RecipeAdminModule),
          },
          {
            path: 'recipes/:id/diff',
            loadChildren: () =>
              import('./pages/recipe-diff-page/recipe-diff-page.module').then(m => m.RecipeDiffPageModule),
          },
          {
            path: 'recipes/:recipeId/job',
            loadChildren: () => import('./pages/job-details/job-details.module').then(m => m.JobDetailsModule),
          },
          {
            path: 'recipes/:recipeId/test_cases',
            loadChildren: () =>
              import('./pages/recipe-test-case/recipe-test-case.module').then(m => m.RecipeTestCaseModule),
          },
          {
            path: 'recipes/browse',
            pathMatch: 'full',
            redirectTo: 'browse/recipes',
          },
          {
            path: 'browse',
            canLoad: [browsePageGuard],
            loadChildren: () => import('./pages/browse-page/browse.module').then(m => m.BrowseModule),
          },
          {
            path: 'recipes/import',
            loadChildren: () => import('./pages/recipe-import/recipe-import.module').then(m => m.RecipeImportModule),
          },
          {
            path: 'recipes/new',
            loadChildren: () =>
              import('./pages/create-recipe-wizard/create-recipe-wizard.module').then(m => m.CreateRecipeWizardModule),
          },
          {
            path: 'recipes',
            loadChildren: () => import('./pages/recipe-page/recipe-page.module').then(m => m.RecipePageModule),
          },
          {
            path: 'profiles',
            loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
          },
          {
            path: 'backoffice/admin',
            loadChildren: () =>
              import('./pages/backoffice/backoffice-admin.module').then(m => m.BackOfficeProfileModule),
          },
          {
            path: 'account_properties',
            loadChildren: () =>
              import('./pages/account-properties/account-properties.module').then(m => m.AccountPropertiesModule),
          },
          {
            path: 'files',
            loadChildren: () => import('./pages/file-storage/file-storage.module').then(m => m.FileStorageModule),
          },
          {
            path: 'users/current/edit',
            loadChildren: () => import('./pages/user-settings/user-settings.module').then(m => m.UserSettingsModule),
          },
          {
            path: 'custom_oauth_keys',
            loadChildren: () =>
              import('./pages/custom-oauth-keys/custom-oauth-keys.module').then(m => m.CustomOauthKeysModule),
          },
          {
            path: 'custom_adapters',
            loadChildren: () =>
              import('./pages/custom-adapters/custom-adapters.module').then(m => m.CustomAdaptersModule),
          },
          {
            path: 'api_management',
            loadChildren: () => import('./pages/api-management/api-management.module').then(m => m.ApiManagementModule),
          },
          {
            path: 'api_groups/:id/endpoints/:endpointId',
            loadChildren: () => import('./pages/api-management/api-endpoint.module').then(m => m.ApiEndpointModule),
          },
          {
            path: 'api_groups',
            loadChildren: () => import('./pages/api-management/api-groups.module').then(m => m.ApiGroupsModule),
          },
          {
            path: 'api_customers',
            loadChildren: () => import('./pages/api-management/api-clients.module').then(m => m.ApiClientsModule),
          },
          {
            matcher: teamMembersUrlMatcher,
            loadChildren: () => import('./pages/team-members/team-members.module').then(m => m.TeamMembersModule),
          },
          {
            matcher: teamRolesUrlMatcher,
            loadChildren: () => import('./pages/team-roles/team-roles.module').then(m => m.TeamRolesModule),
          },
          {
            path: 'connections',
            loadChildren: () => import('./pages/connections/connections.module').then(m => m.ConnectionsModule),
          },
          {
            path: 'accelerators',
            loadChildren: () =>
              import('./pages/recipe-collections/recipe-collections.module').then(m => m.RecipeCollectionsModule),
          },
          {
            matcher: packagesUrlMatcher,
            loadChildren: () => import('./pages/packages/packages-page.module').then(m => m.PackagesPageModule),
          },
          {
            path: 'admin_console',
            loadChildren: () => import('./pages/oem-admin/oem-admin.module').then(m => m.OemAdminModule),
          },
          {
            path: 'organization_console',
            loadChildren: () =>
              import('./pages/federation-admin/federation-admin.module').then(m => m.FederationAdminModule),
          },
          {
            path: 'tools',
            loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule),
          },
          {
            path: 'topics',
            loadChildren: () => import('./pages/topics/topics.module').then(m => m.TopicsModule),
          },
          {
            path: 'workbots',
            loadChildren: () => import('./pages/workbots/workbots.module').then(m => m.WorkbotsModule),
          },
          {
            path: 'embedded',
            loadChildren: () => import('./pages/embedded/embedded.module').then(m => m.EmbeddedModule),
          },
          {
            path: 'community_admin',
            loadChildren: () =>
              import('./pages/community-admin/community-admin.module').then(m => m.CommunityAdminModule),
          },
          {
            path: 'unsupported_connectors',
            loadChildren: () =>
              import('./pages/unsupported-connectors/unsupported-connectors.module').then(
                m => m.UnsupportedConnectorsModule,
              ),
          },
          {
            path: 'runtime_connections',
            loadChildren: () =>
              import('./pages/runtime-connections/runtime-connections.module').then(m => m.RuntimeConnections),
          },
          {
            path: 'lcap/pages/new',
            loadChildren: () =>
              import('./pages/create-lcap-page-wizard/create-lcap-page-wizard.module').then(
                m => m.CreateLcapPageWizardModule,
              ),
          },
          {
            path: 'lcap/pages',
            loadChildren: () =>
              import('./pages/lcap-page-editor/lcap-page-editor.module').then(m => m.LcapPageEditorModule),
          },
          {
            path: 'lcap/portal',
            loadChildren: () => import('./pages/lcap-portal/lcap-portal.module').then(m => m.LcapPortalModule),
          },
          {
            path: 'projects/new',
            loadChildren: () =>
              import('./pages/create-project-wizard/create-project-wizard.module').then(
                m => m.CreateProjectWizardModule,
              ),
          },
          {
            path: 'insights',
            loadChildren: () =>
              import('./pages/insights-dashboards/insights-dashboards.module').then(m => m.InsightsDashboardsModule),
          },
          {
            path: 'usage',
            loadChildren: () =>
              import('./modules/usage-dashboard/usage-dashboard.module').then(m => m.UsageDashboardModule),
          },
          {
            path: 'tags',
            loadChildren: () => import('./pages/asset-tags/asset-tags-page.module').then(m => m.AssetTagsPageModule),
          },
          {
            path: 'marketplace',
            loadChildren: () => import('./pages/marketplace/marketplace.module').then(m => m.MarketplaceModule),
          },
          {
            // Service pages that don't change URL of the current page (using `skipLocationChange` option during navigation)
            path: '_ng_',
            loadChildren: () => import('./pages/service-pages/service-pages.module').then(m => m.ServicePagesModule),
            data: {
              routeName: 'servicePages',
            },
          },
        ]),
      },
      {
        path: 'users/sign_in',
        loadChildren: () => import('./pages/users/sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: 'users/sign_up',
        loadChildren: () => import('./pages/users/sign-up/sign-up.module').then(m => m.SignUpModule),
      },
      {
        path: 'users/email_change',
        loadChildren: () => import('./pages/email-change/email-change.module').then(m => m.EmailChangeModule),
      },
      {
        path: 'users/password',
        loadChildren: () =>
          import('./pages/users/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: 'users/password_expired',
        loadChildren: () =>
          import('./pages/users/expired-password/expired-password.module').then(m => m.ExpiredPasswordModule),
      },
      {
        path: 'users/activation_link_expired',
        loadChildren: () =>
          import('./pages/users/activation-link-expired/activation-link-expired.module').then(
            m => m.ActivationLinkExpiredModule,
          ),
      },
      {
        path: 'users/new_activation_email_sent',
        loadChildren: () =>
          import('./pages/users/new-activation-email-sent/new-activation-email-sent.module').then(
            m => m.NewActivationEmailSentModule,
          ),
      },
      {
        path: 'users/support_session_password_edit',
        loadChildren: () =>
          import('./pages/users/delegated-admin-reset-password/delegate-admin-reset-password.module').then(
            m => m.DelegateAdminResetPasswordModule,
          ),
      },
      {
        path: 'users/support_session_password_new',
        loadChildren: () =>
          import('./pages/users/delegated-admin-forgot-password/delegate-admin-forgot-password.module').then(
            m => m.DelegateAdminForgotPasswordModule,
          ),
      },
      {
        path: 'users/two_factor_authentication',
        loadChildren: () => import('./pages/users/two-fa/two-fa.module').then(m => m.TwoFaModule),
      },
      {
        path: 'users/two_fa_setup',
        loadChildren: () => import('./pages/users/two-fa-setup/two-fa-setup.module').then(m => m.TwoFaSetupModule),
      },
      {
        path: 'users/password_verification',
        loadChildren: () =>
          import('./pages/users/password-verification/password-verification.module').then(
            m => m.PasswordVerificationModule,
          ),
      },
      {
        path: 'users/email_verification',
        loadChildren: () =>
          import('./pages/users/email-verification/email-verification.module').then(m => m.EmailVerificationModule),
      },
      {
        path: 'users/auth/:ssoApp/callback',
        loadChildren: () =>
          import('./pages/users/sso-link-account/sso-link-account.module').then(m => m.SsoLinkAccountModule),
      },
      {
        path: 'users/support_session',
        loadChildren: () =>
          import('./pages/users/support-session/support-session.module').then(m => m.SupportSessionModule),
      },
      {
        path: 'users/invites',
        loadChildren: () => import('./pages/invites/invites.module').then(m => m.InvitesModule),
      },
      {
        path: 'direct_link',
        loadChildren: () =>
          import('./pages/direct-link-error/direct-link-error.module').then(m => m.DirectLinkErrorModule),
      },
      {
        path: 'saml',
        loadChildren: () => import('./pages/saml/saml.module').then(m => m.SamlModule),
      },
      {
        path: 'workflow_users/password',
        loadChildren: () =>
          import('./pages/people-tasks/people-tasks-password.module').then(m => m.PeopleTasksPasswordModule),
      },
      {
        path: 'workflow_users/sign_in',
        loadChildren: () =>
          import('./pages/people-tasks/people-tasks-sign-in.module').then(m => m.PeopleTasksSignInModule),
      },
      {
        path: 'workflow_tasks',
        loadChildren: () => import('./pages/people-tasks/people-tasks.module').then(m => m.PeopleTasksModule),
      },
      {
        path: 'workflow_users/current',
        loadChildren: () =>
          import('./pages/people-tasks/people-tasks-users.module').then(m => m.PeopleTasksUsersModule),
      },
      {
        path: 'api_portals',
        loadChildren: () => import('./pages/api-portal/api-portal.module').then(m => m.ApiPortalModule),
      },
    ],
  },
  {
    path: '**',
    // Using `canActivate` guard here to delay pages init until `AuthUser` is ready.
    canActivate: [authUserInitializedGuard],
    component: AppLayoutComponent,
    loadChildren: () => import('./pages/service-pages/service-pages.module').then(m => m.ServicePagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
