import {Component, OnInit} from '@angular/core';

import {PageService} from '../../../services/page.service';
import {AuthUser} from '../../../services/auth-user';

@Component({
  selector: 'w-page-load-error',
  templateUrl: './page-load-error.component.html',
})
export class PageLoadErrorComponent implements OnInit {
  constructor(
    private pageService: PageService,
    private authUser: AuthUser,
  ) {}

  ngOnInit() {
    this.pageService.addClass('error-page');
  }

  get image(): 'default' | 'genie' {
    return this.authUser.agentic ? 'genie' : 'default';
  }

  reloadPage() {
    // Using full page reload in case there are some problems with client/server version mismatch
    location.reload();
  }
}
