import _ from 'lodash';
import {v4 as uuid} from 'uuid';
import {Injectable} from '@angular/core';

import {
  ConditionType,
  ConditionsConfig,
  FieldsInput,
  Operation,
  RawOperation,
  RecipeStep,
  RecipeStepHelp,
  RecipeStepWithAction,
  SchemaField,
  Step,
  StepAlias,
  StepInput,
  StepKeyword,
  TriggerType,
} from '../types';
import {
  AddRecipeStepPosition,
  PasteRecipeStepPosition,
  RecipeStepRelativePosition,
} from '../modules/recipe-steps/recipe-step.types';
import {RecipeParametersStep} from '../modules/recipe-parameters/recipe-parameters-step';
import {FieldHelper, FieldQualifiedPath} from '../modules/form-fields/field-helper.service';
import {OptionalFieldsHelper} from '../modules/recipe-editor/optional-fields-helper';

import {AdaptersService} from './adapters';
import {StepTraverser} from './step-traverser.service';
import {SchemaHelper} from './schema-helper';
import {KEYWORDS_CONFIG} from './app-dictionary/data';

const KEYWORDS = Object.keys(KEYWORDS_CONFIG) as StepKeyword[];
const IMMOVABLE_STEPS = new Set<StepKeyword>(['catch', 'while_condition']);
const PARENT_LEVEL_STEPS = new Set<StepKeyword>(['catch', 'elsif', 'else']);
const INDENTED_STEPS = new Set<StepKeyword>(['catch']);
const STEPS_WITH_DATATREE_SUPPORT = new Set<StepKeyword>(['if', 'elsif', 'foreach', 'stop', 'while_condition']);
const STEPS_WITH_CHILDREN = new Set<StepKeyword>(KEYWORDS.filter(keyword => KEYWORDS_CONFIG[keyword].block));
const STEPS_WITH_CONFIG = new Set<StepKeyword>(KEYWORDS.filter(keyword => KEYWORDS_CONFIG[keyword].config));
const STEP_INPUT_KEYS: Array<keyof StepInput> = [
  'input',
  'filter',
  'source',
  'repeat_mode',
  'batch_size',
  'clear_scope',
  'external_input_definition',
];

type ValueValidator<TValue> = (value: unknown) => value is TValue;

interface Identifiers {
  uuid?: string;
  as?: string;
}

export interface StepIdentifiersChangePayload {
  step: RecipeStep;
  oldIdentifiers: Identifiers;
  newIdentifiers: Identifiers;
}

export interface StepInsertionTarget {
  step: RecipeStep;
  index: number;
}

@Injectable({
  providedIn: 'root',
})
export class StepHelper {
  constructor(
    private adapters: AdaptersService,
    private stepTraverser: StepTraverser,
    private schemaHelper: SchemaHelper,
    private fieldHelper: FieldHelper,
    private optionalFieldsHelper: OptionalFieldsHelper,
  ) {}

  isActionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'action';
  }

  isTriggerStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'trigger';
  }

  isOperationStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return this.isTriggerStep(step) || this.isActionStep(step);
  }

  isIfConditionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'if';
  }

  isElseIfConditionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'elsif';
  }

  isElseConditionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'else';
  }

  isConditionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return this.isIfConditionStep(step) || this.isElseIfConditionStep(step) || this.isWhileConditionStep(step);
  }

  isRepeatForEachStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'foreach';
  }

  isRepeatWhileStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'repeat';
  }

  isRepeatStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return this.isRepeatForEachStep(step) || this.isRepeatWhileStep(step);
  }

  isWhileConditionStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'while_condition';
  }

  isStopStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'stop';
  }

  isCatchStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.keyword === 'catch';
  }

  isConfiguredOperationStep(step: RecipeStep): step is RecipeStepWithAction {
    return Boolean(this.isOperationStep(step) && step.provider && step.name);
  }

  isLcapAppFunctionReturnStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.provider === 'workato_workflow_task' && step.name === 'app_function_return';
  }

  isFunctionReturnStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.provider === 'workato_recipe_function' && step.name === 'return_result';
  }

  isApiResponseReturnStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.provider === 'workato_api_platform' && step.name === 'return_response';
  }

  isApiProxyResponseStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return step.provider === 'workato_api_proxy' && step.name === 'return_response';
  }

  isParentLevelStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return PARENT_LEVEL_STEPS.has(step.keyword);
  }

  // Indicates a step, which is indented right to it's children indentation level
  isIndentedStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return INDENTED_STEPS.has(step.keyword);
  }

  isImmovableStep<TStep extends Step<TStep>>(step: TStep): boolean {
    return IMMOVABLE_STEPS.has(step.keyword);
  }

  hasImmovableStepAsLastRenderedChild<TStep extends Step<TStep>>(step: TStep): boolean {
    const lastChild = _.last(step.block);

    return Boolean(lastChild && this.isImmovableStep(lastChild) && !this.isParentLevelStep(lastChild));
  }

  supportsDatatree<TStep extends Step<TStep>>(step: TStep): boolean {
    return STEPS_WITH_DATATREE_SUPPORT.has(step.keyword);
  }

  supportsChildSteps<TStep extends Step<TStep>>(step: TStep): boolean {
    return STEPS_WITH_CHILDREN.has(step.keyword);
  }

  isFilterVisible(step: RecipeStep, input = step.input): boolean {
    if (!this.isFilterSupported(step)) {
      return false;
    }

    if (this.isCatchStep(step)) {
      return _.isNil(input?.max_retry_count) || _.toNumber(input.max_retry_count) > 0;
    }

    return true;
  }

  isFilterSupported(step: RecipeStep): boolean {
    return (this.isTriggerStep(step) && Boolean(step.config?.filter_support)) || this.isCatchStep(step);
  }

  isConfigurable(step: RecipeStep): boolean {
    return STEPS_WITH_CONFIG.has(step.keyword) && !step.sketch;
  }

  isUnskipAllowed(step: RecipeStep, rootStep: RecipeStep): boolean {
    const parentStep = this.stepTraverser.findParentStep(rootStep, step);

    return !parentStep?.skip;
  }

  isSkipAllowed(step: RecipeStep, rootStep: RecipeStep): boolean {
    const parentStep = this.stepTraverser.findParentStep(rootStep, step);

    return this.isDeleteAllowed(step) && !parentStep?.skip;
  }

  isDeleteAllowed(step: RecipeStep): boolean {
    return !this.isTriggerStep(step) && !this.isImmovableStep(step);
  }

  isPasteAllowed(
    position: PasteRecipeStepPosition,
    relativeToStep: RecipeStep,
    steps: RecipeStep[],
    rootStep: RecipeStep,
  ): boolean {
    const isTriggerCopied = steps.some(step => this.isTriggerStep(step));
    const isSingleTriggerCopied = isTriggerCopied && steps.length === 1;

    if (isTriggerCopied) {
      // Ignore the trigger: it might be skipped during pasting via user confirmation or if it cannot be pasted
      steps = steps.filter(step => !this.isTriggerStep(step));
    }

    const target = this.resolveStepInsertionTarget(position, relativeToStep, steps, rootStep);

    if (!target) {
      return false;
    }

    if (!isTriggerCopied && position === 'at-root') {
      return false;
    }

    if (isSingleTriggerCopied && (!this.isTriggerStep(target.step) || target.index !== 0)) {
      return false;
    }

    return this.canInsertStepsAt(steps, target.step, target.index);
  }

  isMoveAllowed(
    position: AddRecipeStepPosition,
    relativeToStep: RecipeStep,
    steps: RecipeStep[],
    rootStep: RecipeStep,
  ): boolean {
    const target = this.resolveStepInsertionTarget(position, relativeToStep, steps, rootStep);

    if (!target) {
      return false;
    }

    return this.canInsertStepsAt(steps, target.step, target.index);
  }

  isMaskDataAllowed(step: RecipeStep): boolean {
    return !step.skip && step.keyword !== 'else';
  }

  isCopyAllowed(step: RecipeStep): boolean {
    return !this.isImmovableStep(step) && !this.isEmptyTrigger(step);
  }

  isEmptyTrigger(step: RecipeStep): boolean {
    return this.isTriggerStep(step) && !step.name;
  }

  isFirstChildOfTrigger(step: RecipeStep, rootStep: RecipeStep): boolean {
    return step === rootStep.block?.[0];
  }

  isDeleteConfirmationRequired(step: RecipeStep): boolean {
    const children = step.block || [];

    if (children.some(childStep => this.isDeleteConfirmationRequired(childStep))) {
      return true;
    }

    const {provider, name, input, keyword, source} = step;

    switch (keyword) {
      case 'trigger':
      case 'action':
        if (!provider || !name) {
          return false;
        }

        return this.schemaHelper.hasMapping(this.getFieldsSchema(step), {input: input || {}, step}, {mode: 'some'});
      case 'foreach':
        return Boolean(source);
      case 'if':
      case 'elsif':
      case 'while_condition':
        return (input as ConditionsConfig).conditions.some(condition => condition.lhs);
      case 'else':
      case 'stop':
      case 'catch':
      case 'repeat':
        return false;
      default:
        return true;
    }
  }

  getDynamicPicklistValue<TValue>(
    step: RecipeStep,
    fieldPath: FieldQualifiedPath,
    validator?: ValueValidator<TValue>,
  ): TValue | null {
    const value = _.get(step, this.getDynamicPicklistPath(fieldPath));

    if (validator && !validator(value)) {
      this.setDynamicPicklistValue(null, step, fieldPath);

      return null;
    }

    return value;
  }

  setDynamicPicklistValue<TValue>(value: TValue, step: RecipeStep, fieldPath: FieldQualifiedPath) {
    const pickListPath = this.getDynamicPicklistPath(fieldPath);

    if (_.isEmpty(value)) {
      _.unset(step, pickListPath);
    } else {
      _.set(step, pickListPath, value);
    }
  }

  getTriggerType(rootStep: RecipeStep): TriggerType {
    if (!this.isTriggerStep(rootStep) || !rootStep.provider || !rootStep.name) {
      return 'unknown';
    }

    const triggerConfig = this.adapters.getActionConfig(rootStep.provider, 'trigger', rootStep.name);

    if (!triggerConfig) {
      return 'unknown';
    }

    if (triggerConfig.realtime && triggerConfig.no_poll) {
      return 'realtime';
    }

    return triggerConfig.realtime ? 'mixed' : 'polling';
  }

  getConditionType(step: RecipeStep): ConditionType | null {
    switch (true) {
      case this.isIfConditionStep(step):
        return 'if';
      case this.isWhileConditionStep(step):
        return 'while';
      case this.isElseIfConditionStep(step):
        return 'else_if';
      default:
        return null;
    }
  }

  getUuid(): string {
    return uuid();
  }

  buildIdentifiersFor(step: RecipeStep, rootStep: RecipeStep = step): StepIdentifiersChangePayload[] {
    const updatedIdentifiers: StepIdentifiersChangePayload[] = [];
    const generatedAliases = new Set<string>();

    this.stepTraverser.walk(step, nestedStep => {
      const oldIdentifiers: Identifiers = {
        uuid: nestedStep.uuid,
        as: nestedStep.as,
      };
      const newIdentifiers: Identifiers = {
        uuid: this.getUuid(),
      };

      if (this.stepNeedsAlias(nestedStep)) {
        newIdentifiers.as = this.generateStepAlias(nestedStep, rootStep, generatedAliases)!;
        generatedAliases.add(newIdentifiers.as);
      }

      updatedIdentifiers.push({
        step: nestedStep,
        oldIdentifiers,
        newIdentifiers,
      });
    });

    return updatedIdentifiers;
  }

  generateStepAlias(step: RecipeStep, rootStep: RecipeStep | undefined, excluded?: Set<string>): string | undefined {
    if (!this.stepNeedsAlias(step)) {
      return undefined;
    }

    let alias: string;

    do {
      alias = this.getUuid().split('-', 1)[0];
    } while (excluded?.has(alias) || this.isAliasInUse(alias, rootStep));

    return alias;
  }

  getConfig<TStep extends Step<TStep>>(step: TStep): RawOperation | undefined {
    if (this.isOperationStep(step)) {
      return this.adapters.getActionConfig(step.provider, step.keyword, step.name) || undefined;
    }
  }

  getDefaultStepHelp(step: RecipeStep): RecipeStepHelp | undefined {
    if (this.isWhileConditionStep(step)) {
      return {
        body: `Create a loop that repeats a fixed number of times, or fetches many pages of data.
        Steps run at least once, and keep iterating while a condition is met.
        To prevent an infinite loop, ensure the condition allows the loop to exit. <a href='https://docs.workato.com/recipes/loops.html' target='_blank'>See examples</a>`,
      };
    }

    if (this.isCatchStep(step)) {
      return {
        body: `
          <p>When errors occur in the <b>Monitor</b> block, actions in this <b>Error</b> block will be carried out.</p>
          <p>The <b>Monitor</b> block can be conditionally retried up to 3 times. If all retry attempts fail, <b>Error</b> block actions will be carried out.</p>
          <a href="https://docs.workato.com/recipes/steps.html#handle-errors-step"
            target="_blank">Learn more</a>`,
      };
    }
  }

  getFieldsSchema(step: RecipeStep): SchemaField[] {
    const inputSchema = _.cloneDeep(this.adapters.getInputSchema(step.provider, step.keyword, step.name));
    const rawExtendedInputSchema = _.cloneDeep(step.extended_input_schema);
    const missedExtendedInputFields = _.cloneDeep(this.getMissedExtendedInputFields(step));
    const parts = _.partition(rawExtendedInputSchema, {override: true});
    const overrideInputSchema = parts[0];
    const extendedInputSchema = parts[1];

    _.forEach(overrideInputSchema, overrideField => {
      const inputField = _.find(inputSchema, {name: overrideField.name});

      _.assign(inputField, overrideField);
    });

    const schema = _.chain(inputSchema).concat(extendedInputSchema).concat(missedExtendedInputFields).compact().value();

    this.schemaHelper.defineParentForSchema(schema);

    return schema;
  }

  getFieldsSchemaSize(step: RecipeStep): number {
    const inputSchema = this.adapters.getInputSchema(step.provider, step.keyword, step.name);
    const extendedInputSchema = (step.extended_input_schema || []).filter(field => !field.override);
    const missedExtendedInputFields = this.getMissedExtendedInputFields(step);

    return inputSchema.length + extendedInputSchema.length + missedExtendedInputFields.length;
  }

  getStepSchema(step: RecipeStep): SchemaField[];
  getStepSchema(step: RecipeParametersStep, fields: SchemaField[]): SchemaField[];

  getStepSchema(step: RecipeStep | RecipeParametersStep, parameterFields?: SchemaField[]): SchemaField[] {
    let schema: SchemaField[];

    if (step instanceof RecipeParametersStep) {
      schema = [{name: 'param', properties: _.cloneDeep(parameterFields) || []}];
    } else {
      const properties = this.isOperationStep(step)
        ? this.getFieldsSchema(step)
        : this.schemaHelper.getSchemaByKeyword(step.keyword);

      // We store foreach step input in the step object itself (not within `step.input`).
      schema = this.isRepeatForEachStep(step) ? properties : [{name: 'input', properties}];

      if (this.isTriggerStep(step) || this.isCatchStep(step)) {
        schema.push({name: 'filter', properties: [this.schemaHelper.getFilterField(step.keyword)]});
      }

      if (this.isActionStep(step)) {
        schema.push({
          name: 'external_input_definition',
          properties: [this.schemaHelper.getDynamicMappingSourceField()],
        });
      }
    }

    this.schemaHelper.defineParentForSchema(schema);

    return schema;
  }

  getStepInput(step: RecipeStep): StepInput;
  getStepInput(step: RecipeParametersStep, recipeParametersInput: FieldsInput): StepInput;

  getStepInput(step: RecipeStep | RecipeParametersStep, recipeParametersInput?: FieldsInput): StepInput {
    if (step instanceof RecipeParametersStep) {
      return {param: recipeParametersInput};
    }

    return _.pick(step, STEP_INPUT_KEYS);
  }

  getMissedExtendedInputFields(step: RecipeStep): SchemaField[] {
    const schema: SchemaField[] = step.requirements?.extended_input_schema ?? [];

    return _.filter(schema, field => {
      const hasInput = _.has(step.input, field.name);
      const hasMissingSchema = !_.some(step.extended_input_schema, {name: field.name});

      return hasInput && hasMissingSchema;
    });
  }

  getAvailableOperations(step: RecipeStep): Operation[] {
    if (!step.provider) {
      return [];
    }

    const operations = this.isTriggerStep(step)
      ? this.adapters.triggers(step.provider)
      : this.adapters.actions(step.provider);

    return operations.filter(operation => !operation.deprecated || operation.name === step.name);
  }

  getActionApplications(rootStep: RecipeStep): string[] {
    const result = new Set<string>();

    this.stepTraverser.walk(rootStep, step => {
      if (this.isActionStep(step) && step.provider) {
        result.add(step.provider);
      }
    });

    return Array.from(result);
  }

  /*
   * Count child steps according to the `mode` specified:
   * `as-stored`: count child steps as they are stored within recipe data model
   * `as-rendered`: count child steps as they are visually rendered in the interface(excluding parent-level steps)
   * `as-inserted`: same as `as-rendered` but also excluding any built-in immovable steps
   */
  getChildCount(step: RecipeStep, mode: 'as-inserted' | 'as-rendered' | 'as-stored'): number {
    const children = step.block ?? [];

    switch (mode) {
      case 'as-inserted':
        return children.filter(child => !this.isParentLevelStep(child) && !this.isImmovableStep(child)).length;
      case 'as-rendered':
        return children.filter(child => !this.isParentLevelStep(child)).length;
      case 'as-stored':
        return children.length;
    }
  }

  /*
   * Returns the name of the field, which contains business object name.
   * Business object defines what fields are there in the step.
   */
  getBusinessObjectFieldName(step: RecipeStep): SchemaField['name'] | null {
    const {provider, keyword, name: operation} = step;
    const config = this.adapters.getActionConfig(provider, keyword, operation);

    return config?.type_field || null;
  }

  /*
   * At some point we decided to replace step comments with custom step titles, but feedback was mostly negative.
   * Because of this we brought comments back, but still need to support already set custom titles.
   * So if custom title is set, we show it in place of comment.
   */
  getComment(step: RecipeStep): string {
    return step.custom_title || step.comment || '';
  }

  hasTrigger(step: RecipeStep): boolean {
    return this.getTriggerType(step) !== 'unknown';
  }

  stepNeedsAlias(step: RecipeStep): boolean {
    // Step has/needs alias only when it may have output or could have Test Automation assertions
    return (
      this.isConfiguredOperationStep(step) || this.isRepeatStep(step) || this.isCatchStep(step) || this.isStopStep(step)
    );
  }

  /**
   * Finds a step to which a straight arrow or a subtree opening arrow is pointing from a given step
   * It can be either first child, or sibling or null
   */
  getNextStep(step: RecipeStep, rootStep: RecipeStep): RecipeStep | null {
    if (STEPS_WITH_CHILDREN.has(step.keyword)) {
      return this.getFirstChildLevelStep(step) ?? null;
    }

    return this.stepTraverser.findSibling(rootStep, step, 'next');
  }

  /**
   * Finds a step to which a subtree end arrow is pointing from a given step
   * It is mainly next sibling, but for if-else and try-catch blocks,
   * it can be either last child or parent's sibling
   */
  getStepAfterBlock(step: RecipeStep, rootStep: RecipeStep): RecipeStep | null {
    return this.getAdjacentSameLevelStep('next', step, rootStep) ?? null;
  }

  calculateNestingLevel<TStep extends Step<TStep>>(parentStep: TStep, step: TStep): number {
    if (parentStep === step) {
      return 0;
    }

    const children = parentStep.block || [];

    return (
      Math.max(
        ...children.map(child => {
          const increment = this.isParentLevelStep(child) ? 0 : 1;

          return increment + this.calculateNestingLevel(child, step);
        }),
      ) || -1
    );
  }

  shouldShowConfigField(step: RecipeStep, field: SchemaField): boolean {
    if (this.isRepeatForEachStep(step) && field.name === 'batch_size') {
      return step.repeat_mode === 'batch';
    }

    if (this.isStopStep(step) && field.name === 'stop_reason') {
      return step.input?.stop_with_error === 'true';
    }

    if (this.isCatchStep(step) && field.name === 'retry_interval') {
      return step.input?.max_retry_count > 0;
    }

    return true;
  }

  hasEmptyOrDefaultValue(step: RecipeStep, schema: SchemaField[]): boolean {
    return (
      _.isEmpty(step.input) ||
      !this.schemaHelper.hasMapping(schema, {input: step.input, step}, {mode: 'some', ignoreDefaults: true})
    );
  }

  getStepAliasSet(rootStep: RecipeStep): Set<StepAlias> {
    const stepAliasSet = new Set<StepAlias>();

    this.stepTraverser.walk(rootStep, step => {
      if (step.as) {
        stepAliasSet.add(step.as);
      }
    });

    return stepAliasSet;
  }

  getLastSameLevelStep(step: RecipeStep): RecipeStep {
    return step.block?.findLast(child => this.isParentLevelStep(child)) ?? step;
  }

  getFirstChildLevelStep(step: RecipeStep): RecipeStep | undefined {
    return step.block?.find(child => !this.isParentLevelStep(child));
  }

  getLastChildLevelStep(step: RecipeStep): RecipeStep | undefined {
    return step.block?.findLast(child => !this.isParentLevelStep(child));
  }

  getChildLevelSteps(step: RecipeStep): RecipeStep[] {
    return step.block?.filter(child => !this.isParentLevelStep(child)) ?? [];
  }

  getParentLevelSteps(step: RecipeStep): RecipeStep[] {
    return step.block?.filter(child => this.isParentLevelStep(child)) ?? [];
  }

  getAdjacentSameLevelStep(
    position: 'prev' | 'next',
    adjacentTo: RecipeStep,
    rootStep: RecipeStep,
  ): RecipeStep | undefined {
    let siblings: RecipeStep[];
    const parentStep = this.stepTraverser.findParentStep(rootStep, adjacentTo) ?? rootStep;

    if (this.isParentLevelStep(adjacentTo)) {
      const grandParentStep = this.stepTraverser.findParentStep(rootStep, parentStep);

      if (!grandParentStep) {
        // Result includes parent step and all it's parent-level children
        siblings = [parentStep, ...this.getParentLevelSteps(parentStep)];
      } else {
        // Result includes all siblings of parent step and all their parent-level children
        siblings = this.getChildLevelSteps(grandParentStep).flatMap(step => [step, ...this.getParentLevelSteps(step)]);
      }
    } else {
      // Result includes children of parent step and all their parent-level children
      siblings = this.getChildLevelSteps(parentStep).flatMap(step => [step, ...this.getParentLevelSteps(step)]);
    }

    const stepIndex = siblings.findIndex(sibling => sibling === adjacentTo);

    return siblings[stepIndex + (position === 'prev' ? -1 : 1)];
  }

  getDisallowedKeywordsBefore(step: RecipeStep | undefined | null): StepKeyword[] {
    if (step && (this.isElseIfConditionStep(step) || this.isElseConditionStep(step))) {
      // Cannot insert anything except `elsif` before other `elsif` or `else`
      return KEYWORDS.filter(keyword => keyword !== 'elsif');
    }

    return [];
  }

  getDisallowedKeywordsAfter(step: RecipeStep | undefined | null): StepKeyword[] {
    if (!step || (!this.isIfConditionStep(step) && !this.isElseIfConditionStep(step))) {
      // Cannot insert `elsif` or `else` after anything but `if` or `elsif`
      return ['elsif', 'else'];
    }

    return [];
  }

  getDisallowedKeywordsBetween(
    prevSameLevelStep: RecipeStep | undefined | null,
    nextSameLevelStep: RecipeStep | undefined | null,
  ): StepKeyword[] {
    return _.uniq([
      ...this.getDisallowedKeywordsAfter(prevSameLevelStep),
      ...this.getDisallowedKeywordsBefore(nextSameLevelStep),
    ]);
  }

  resolveStepInsertionTarget(
    position: RecipeStepRelativePosition,
    relativeToStep: RecipeStep,
    insertedSteps: RecipeStep[],
    rootStep: RecipeStep,
  ): StepInsertionTarget | null {
    const isRelativeToParentLevelStep = this.isParentLevelStep(relativeToStep);
    const isInsertingParentLevelSteps = insertedSteps.some(step => this.isParentLevelStep(step));

    // 'above' position is resolved into either 'before' current step, or 'last-child' of the previous step, or 'first-child' of trigger
    if (position === 'above') {
      const prevSameLevelStep = this.getAdjacentSameLevelStep('prev', relativeToStep, rootStep);

      if (prevSameLevelStep && this.supportsChildSteps(prevSameLevelStep) && this.isParentLevelStep(relativeToStep)) {
        relativeToStep = prevSameLevelStep;
        position = 'last-child';
      } else if (this.isFirstChildOfTrigger(relativeToStep, rootStep)) {
        relativeToStep = rootStep;
        position = 'first-child';
      } else {
        position = 'before';
      }
    }

    // 'below' position is resolved into either 'after' current step or 'first-child' of the current step
    if (position === 'below') {
      if (this.supportsChildSteps(relativeToStep)) {
        position = 'first-child';
      } else {
        position = 'after';
      }
    }

    const target: StepInsertionTarget = {
      step: rootStep,
      index: 0,
    };

    const parentStep = this.stepTraverser.findParentStep(rootStep, relativeToStep);

    switch (position) {
      case 'at-root':
        if (isInsertingParentLevelSteps || relativeToStep !== rootStep) {
          // Cannot insert parent-level steps "at-root"
          return null;
        }

        return target;
      case 'first-child':
        if (isInsertingParentLevelSteps) {
          // Cannot insert parent-level steps as "first-child"
          return null;
        }

        target.step = relativeToStep;
        target.index = 0;

        return target;
      case 'last-child':
        // `last-child` insertion position is only available for steps with at least one child
        const lastChildIndex = this.getChildCount(relativeToStep, 'as-rendered');

        // Parent-level steps are inserted inside the last-child step (so that they are at the last-child nesting level)
        if (isInsertingParentLevelSteps) {
          target.step = relativeToStep.block![lastChildIndex - 1];
          target.index = target.step.block?.length ?? 0;
        } else {
          // Regular steps are inserted into the step itself
          target.step = relativeToStep;
          target.index = lastChildIndex;
        }

        return target;
      case 'before':
        if (isInsertingParentLevelSteps && !isRelativeToParentLevelStep) {
          // Cannot insert parent-level steps "before" regular steps
          return null;
        }

        if (!isInsertingParentLevelSteps && isRelativeToParentLevelStep) {
          // Cannot insert regular steps "before" parent-level steps
          return null;
        }

        if (!parentStep) {
          return null;
        }

        target.step = parentStep;
        target.index = _.findIndex(target.step.block, relativeToStep);

        return target;
      case 'after':
        if (isInsertingParentLevelSteps && !isRelativeToParentLevelStep) {
          target.step = relativeToStep;
          target.index = this.getChildCount(relativeToStep, 'as-rendered');
        } else if (!isInsertingParentLevelSteps && isRelativeToParentLevelStep) {
          if (relativeToStep !== _.last(parentStep?.block)) {
            // Cannot insert regular steps in-between parent-level steps
            return null;
          }

          target.step = this.stepTraverser.findParentStep(rootStep, parentStep!)!;
          target.index = _.findIndex(target.step.block, parentStep) + 1;
        } else if (isInsertingParentLevelSteps && isRelativeToParentLevelStep) {
          target.step = parentStep!;
          target.index = _.findIndex(target.step.block, relativeToStep) + 1;
        } else if (!isInsertingParentLevelSteps && !isRelativeToParentLevelStep) {
          if (relativeToStep.block?.some(step => this.isParentLevelStep(step))) {
            // Cannot insert regular steps after another regular step, which also have parent-level children
            return null;
          }

          if (!parentStep) {
            return null;
          }

          target.step = parentStep;
          target.index = _.findIndex(target.step.block, relativeToStep) + 1;
        }

        return target;
    }
  }

  getStepSchemaAndInput(step: RecipeStep): [SchemaField[], FieldsInput] {
    let schema: SchemaField[] = [];

    if (this.isRepeatStep(step) || this.isStopStep(step) || this.isCatchStep(step)) {
      schema = this.schemaHelper.getSchemaByKeyword(step.keyword);
    } else if (this.isTriggerStep(step) || this.isActionStep(step)) {
      schema = this.getFieldsSchema(step);
    }

    const input = this.isRepeatForEachStep(step) ? step : step.input!;

    this.schemaHelper.fillDefaults(schema, input);

    const visibilityMap = this.optionalFieldsHelper.createVisibilityMap(
      schema,
      step.visible_config_fields,
      step.hidden_config_fields,
      input,
    );

    schema = this.optionalFieldsHelper.filterVisible(schema, visibilityMap, input);

    return [schema, input];
  }

  private canInsertStepBefore(targetStep: RecipeStep | undefined | null, insertedStep: RecipeStep): boolean {
    return !this.getDisallowedKeywordsBefore(targetStep).includes(insertedStep.keyword);
  }

  private canInsertStepAfter(targetStep: RecipeStep | undefined | null, insertedStep: RecipeStep): boolean {
    return !this.getDisallowedKeywordsAfter(targetStep).includes(insertedStep.keyword);
  }

  private canInsertStepsAt(steps: RecipeStep[], targetStep: RecipeStep, targetIndex: number): boolean {
    if (!steps.length) {
      return true;
    }

    let prevSameLevelStep: RecipeStep | undefined;
    let nextSameLevelStep: RecipeStep | undefined;

    if (steps.some(step => this.isParentLevelStep(step))) {
      const prevStep = targetStep.block?.[targetIndex - 1];
      const nextStep = targetStep.block?.[targetIndex];

      prevSameLevelStep = prevStep && this.isParentLevelStep(prevStep) ? prevStep : targetStep;
      nextSameLevelStep = nextStep && this.isParentLevelStep(nextStep) ? nextStep : undefined;
    } else {
      const sameLevelSteps = this.getChildLevelSteps(targetStep);

      prevSameLevelStep = sameLevelSteps[targetIndex - 1];
      nextSameLevelStep = sameLevelSteps[targetIndex];
    }

    const firstTopLevelStep = steps[0];
    const lastTopLevelStep = this.getLastSameLevelStep(_.last(steps)!);

    return (
      this.canInsertStepBefore(nextSameLevelStep, lastTopLevelStep) &&
      this.canInsertStepAfter(prevSameLevelStep, firstTopLevelStep)
    );
  }

  private isAliasInUse(alias: RecipeStep['as'], rootStep: RecipeStep | undefined): boolean {
    if (!rootStep) {
      return false;
    }

    return Boolean(this.stepTraverser.findStep(rootStep, step => step.as === alias));
  }

  private getDynamicPicklistPath = (fieldName: FieldQualifiedPath): string[] => [
    'dynamicPickListSelection',
    this.fieldHelper.joinPathByDot(fieldName),
  ];
}
