<!-- Cannot use interpolation in class property here due to https://github.com/Workato-All/issues/issues/21112 -->
<form
  class="search-field search-field_{{ size }}"
  [class.search-field_focused]="focused"
  [class.search-field_disabled]="disabled"
  (click)="inputElement.focus()"
  (submit)="handleSubmit($event)"
>
  <w-svg-icon class="search-field__icon-search" iconId="search" [size]="size === 'compact' ? 8 : 12" />
  <input
    #inputElement
    class="search-field__input"
    type="text"
    [name]="inputName"
    [placeholder]="placeholder"
    [attr.autocomplete]="autocomplete ? null : 'off'"
    aria-label="Text to search"
    [formControl]="query"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    wKeyboardNavigationItem
    wDisableCompositionBuffer
    [wFocusWhen]="autofocus"
    [wFocusPreventScroll]="autofocusPreventScroll"
  />
  <button
    *ngIf="query.value && !disabled"
    class="search-field__icon-clear"
    type="button"
    (click)="clear()"
    wIconButton="close"
    wIconButtonAriaLabel="Clear search"
  ></button>
</form>
