@if (isWorkspace()) {
  <w-user-avatar
    class="app-context-switcher-icon"
    [borderRadius]="2"
    [imgUrl]="value.avatar_url"
    [userName]="value.name"
    [size]="20"
  />
} @else if (isProduct()) {
  <w-svg-icon
    class="app-context-switcher-icon app-context-switcher-icon_{{ value.id }}"
    [iconId]="PRODUCT_ICONS[value.id]"
    [size]="20"
  />
} @else if (isEnvironment()) {
  <w-svg-icon
    class="app-context-switcher-icon app-context-switcher-icon_{{ value.theme }}"
    [iconId]="ENV_ICONS[value.theme]"
    [size]="12"
  />
} @else if (isLock()) {
  <w-svg-icon class="app-context-switcher-icon app-context-switcher-icon_lock" iconId="lock" [size]="12" />
}
