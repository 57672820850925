import {AfterViewInit, Directive, OnDestroy} from '@angular/core';

import {ElementResizeDirective} from '@shared/directives/element-resize.directive';

import {StickyAreaService} from '../services/sticky-area.service';

// Note: The resize observer would not work on inline elements, make sure to use display: block or flex!
@Directive({
  selector: '[wStickyArea]',
  standalone: true,
  hostDirectives: [ElementResizeDirective],
})
export class StickyAreaDirective implements AfterViewInit, OnDestroy {
  constructor(
    private stickyAreaService: StickyAreaService,
    private resizeDirective: ElementResizeDirective,
  ) {}

  ngAfterViewInit() {
    this.stickyAreaService.add(this.resizeDirective);
  }

  ngOnDestroy() {
    this.stickyAreaService.remove(this.resizeDirective);
  }
}
